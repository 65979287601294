import Vue from "vue";
import VueRouter from "vue-router";
import i18n from '@/helpers/i18n';

//Views
import HomePage from "../views/HomePage.vue";
import Privacy from "../views/Privacy.vue";

//Header and footer
import AppHeader from "../layout/AppHeader.vue";
import AppFooter from "../layout/AppFooter.vue";

// Page 404
import Page404 from "../views/Page404.vue";

Vue.use(VueRouter);

import { CONSTANTS } from '@/helpers/consts';

const routes = [
  {
    path: "/",
    name: "HomePage",
    components: {
      header: AppHeader,
      default: HomePage,
      footer: AppFooter,
    }
  },
  {
    path: i18n.t('privacy'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('privacy', locale.key) ),
    name: 'Privacy',
    components: {
      header: AppHeader,
      default: Privacy
    }
  },
  {
    path: '*',
    name: '404',
    components: {
      header: AppHeader,
      default: Page404,
      footer: AppFooter
    }
  }, 
];

console.log(routes);
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
