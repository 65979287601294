<template>
  <div class="d-flex flex-column">
    <div class="d-flex align-items-center justify-content-center">
      <router-link class="p-2" :to="{ name: 'Privacy' }">{{$t("privacy")}}</router-link>
      <a v-if="$i18n.locale != 'pt'" class="nav-link" href="#" @click.prevent="setLocale('pt')">{{$t("portuguese")}}</a>                 
      <a v-if="$i18n.locale != 'en'" class="nav-link" href="#" @click.prevent="setLocale('en')">{{$t("english")}}</a>
    </div>
    <span class="mb-1">© 2022. ALL RIGHTS RESERVED.</span>
  </div>
</template>

<script>
export default {
  methods: {
      setLocale(locale) {
          this.$i18n.locale = locale;
          localStorage.setItem('locale', locale);
          this.$router.push("/");
          this.$router.go(0);
      },
  }
}
</script>

<style scoped>
span, a
{
	color: white;
}

a
{
  text-decoration: underline;
}

a:hover,
a:focus
{
  color: #a48242;
}
</style>