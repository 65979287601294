<template>
  <div class="home-page d-flex flex-column justify-content-center align-items-center">
    <div v-if="loading" id="fullscreen-loading">
      <LogoLoading/>
    </div>
    <div v-else>
      <Hero v-for="(item, index) in data" :key="index" :ref="item.ref" :id="'section-' + index" :title="item.title" :subtitle="item.subtitle" :src="item.src" :srcAlternative="item.srcAlternative" :cta="item.cta" :scrollTo="item.scrollTo" @scroll="scrollRequested(index)"></Hero>
    </div>
  </div>
</template>

<script>
import { UTILS_HELPER } from "@/helpers/utils.js";
import LogoLoading from "@/components/LogoLoading.vue"
import Hero from "@/components/Hero.vue"
import { API_HELPER } from "@/helpers/api.js";

export default {
  data () {
    return {
      data:
      [
        {ref:"above-the-fold", title: this.$i18n.t("title"), subtitle: this.$i18n.t("aboutUs"), src: 'icon.png', cta: this.$i18n.t("tellMeMore"), scrollTo: 'section-2'},
        {ref:"social", title: this.$i18n.t("social"), subtitle: this.$i18n.t("socialDescription"), src: "social.png", cta: this.$i18n.t("talkWithUs")},
        {ref:"endomkt", title: this.$i18n.t("internalMkt"), subtitle: this.$i18n.t("internalMktDescription"), src: "logo-browse.png", cta: this.$i18n.t("talkWithUs")},
        {ref:"branding", title: this.$i18n.t("branding"), subtitle: this.$i18n.t("brandingDescription"), src: "card.png", cta: this.$i18n.t("talkWithUs")},
        {ref:"events", title: this.$i18n.t("events"), subtitle: this.$i18n.t("eventsDescription"), src: "event.png", cta: this.$i18n.t("talkWithUs")},
        {ref:"workshops", title: this.$i18n.t("workshops"), subtitle: this.$i18n.t("workshopsDescription"), src: "notes.png", cta: this.$i18n.t("talkWithUs")},
      ],
      loading: false
    }
  },
  metaInfo () {
    return { 
      title: 'What When Where',
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: 
      [
        {name: 'description', content: this.$t("aboutUs")},
        {name: 'keywords', content: this.$t("defaultKeywords")},
        // Twitter Card
        {name: 'twitter:card', content: "summary"},
        {name: 'twitter:title', content: 'What When Where'},
        {name: 'twitter:description', content: this.$t("aboutUs")},
        {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL, "logo.png"]) },
        //Facebook OpenGraph
        {property: 'og:title', content: 'What When Where'},
        {property: 'og:site_name', content: 'What When Where'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL, "logo.png"]) },
        {property: 'og:description', content: this.$t("aboutUs")}
      ], 
      link: [
        {rel: 'alternate', hreflang: 'pt', href: process.env.VUE_APP_URL},
        {rel: 'alternate', hreflang: 'en', href: process.env.VUE_APP_URL},
      ]
    } 
  },
  name: "HomePage",
  components: {
    LogoLoading,
    Hero
  },
  mounted()
  {
    // const self = this;
    // setTimeout(() => { self.loading = false; }, 1500);
  },
  created () {
    window.addEventListener('wheel', this.handleScroll, { passive:false });
  },
  destroyed () {
    window.removeEventListener('wheel', this.handleScroll);
  },
  methods: {
    scrollRequested(index)
    {
      const newIndex = index + 1;
      const newRef = this.data[newIndex].ref;
      window.scrollTo({
        top: this.$refs[newRef][0].$el.offsetTop,
        behavior: "smooth"
      });
    },
    handleScroll (event) {  
      event.preventDefault();
      window.removeEventListener('wheel', this.handleScroll);
  
      // Identify current section
      var currentIndex = null;
      for (let index = 0; index < this.data.length; index++) {
        const ref = this.data[index].ref;
        const element = this.$refs[ref][0].$el;
        
        const top = element.offsetTop;
        const bottom = top + element.offsetHeight;
        console.log(top, bottom, window.pageYOffset);
        if(window.pageYOffset >= top && window.pageYOffset < bottom)
        {
          currentIndex = index;
          break;
        }
      }

      // Scroll to next, according to direction
      if(currentIndex !== null)
      {
        var newIndex = null;
        if(event.deltaY < 0)
        {
          if(currentIndex != 0)
          {
            newIndex = currentIndex - 1;
          }
        }
        else if(event.deltaY > 0)
        {
          if(currentIndex < this.data.length - 1)
          {
            newIndex = currentIndex + 1;
          }
        }

        if(newIndex !== null)
        {
          const newRef = this.data[newIndex].ref;
          window.scrollTo({
            top: this.$refs[newRef][0].$el.offsetTop,
            behavior: "smooth"
          });
        }
      }

      window.addEventListener('wheel', this.handleScroll, { passive:false });
    }
  }
};
</script>

<style scoped>
#fullscreen-loading
{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
  z-index: 999;
}

</style>