<template>
  <div class="container d-flex align-items-center min-vh-100">
    <div class="row w-100 justify-content-center">
      <div class="col-md">
        <div class="w-100">
        <div class="no-results d-flex flex-column justify-content-center align-items-center p-5 w-100">
          <img loading=lazy class="no-results-img my-5" src="@/assets/img/logo_white.png" alt="Logo"/>
          <h1 class="float-left display-3 mr-4">404</h1>
          <h4 class="pt-3">{{$t("page404.title")}}</h4>
          <p class="text-muted">{{$t("page404.text")}}</p>
          <button class="btn-border" @click="goHome">{{$t("page404.homePage")}}</button>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page404',
    methods: 
    {
        goHome: function()
        {
            this.$router.push("/");
        }
    }
}
</script>

<style scoped>
.no-results-img
{
    width: 30%;
    min-width: 200px;
}
.btn {
  background: #962422;
  color: white;
  padding: 5px 100px;
  font-size: 20px;
}
.btn:hover {
  color: white;
}
</style>