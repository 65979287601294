<template>
    <section id="app-header">
        <b-navbar class="w-100" toggleable="lg" type="dark">
            <div class="container-fluid">
                <b-navbar-brand class="d-block d-lg-none">
                    <router-link class="nav-link pt-0" active-class="active" exact :to="{ name: 'HomePage' }">
                        <img loading=lazy :src="this.$route.name == 'HomePage' ? require('../assets/img/logo_gold.png') : require('../assets/img/logo_white.png')" alt="Logo" title="Logo" />
                    </router-link>
                </b-navbar-brand>
                <b-collapse class="nav-menu justify-content-between" id="nav-collapse" is-nav>
                    <b-navbar-brand class="d-flex justify-content-start flex-grow-1">
                        <router-link class="nav-link pt-0" active-class="active" exact :to="{ name: 'HomePage' }">
                            <img loading=lazy :src="this.$route.name == 'HomePage' ? require('../assets/img/logo_gold.png') : require('../assets/img/logo_white.png')" alt="Logo" title="Logo" />
                        </router-link>
                    </b-navbar-brand>
                    <b-navbar-nav class="ml-auto fw-bold">
                        <div class="social-container">
                            <ul class="social-icons">
                                <li><a target="_blank" href="https://www.instagram.com/whatwhenwhere__/"><i class="fa fa-instagram"></i></a></li>
                                <li><a target="_blank" href="https://www.facebook.com/whatwhenwhereagency"><i class="fa fa-facebook"></i></a></li>
                                <li><a target="_blank" href="https://www.linkedin.com/company/what-when-where-agency/"><i class="fa fa-linkedin"></i></a></li>
                                <li><a href="mailto:info@whatwhenwhere.pt"><i class="fa fa-envelope"></i></a></li>
                            </ul>
                        </div>
                    </b-navbar-nav>
                </b-collapse>
                <b-button class="d-lg-none" id="navbar-toggle" v-b-toggle.sidebar-right variant="transparent" size="lg">
                    <b-icon icon="list" variant="white"></b-icon>
                </b-button>
                <b-sidebar class="w-100" id="sidebar-right" title="Sidebar" right no-header bg-variant="no-light">
                    <template #default="{ hide }">
                        <SideMenu @close="hide"></SideMenu>
                    </template>
                </b-sidebar>
            </div>
        </b-navbar>
    </section>
</template>

<script>
import SideMenu from '@/components/menus/SideMenu.vue';


export default {
    components: {
        SideMenu
    },
};
</script>

<style scoped>
#app-header{
    height: 112px;
    background-color: transparent;
    position: absolute;
    width: 100%;
    display: flex;
    z-index: 10;
}
.form-inline .form-inline a,
>>>.btn-group > .btn{
    font-size: 0.8rem;
    padding-left: 1rem;
    padding-right: 1rem;
    letter-spacing: .1rem;
    color: #fff;
    transition: all .4s ease-in-out;
    cursor: pointer;
}
.navbar-brand{
    width: auto;
    height: 70px;
}
.navbar-brand img{
    width: auto;
    height: 64px;
}
.form-inline{
    display: flex;
}
>>>.b-sidebar{
    width: 100%;
}
.opacity-active a:not(.active),
.lang-opacity a:not(.active){
    opacity: 1!important;
}
.navbar-wine-detail{
    background-color:#e3e1dd;
}
.navbar-wine-detail .form-inline a{
    color: #6c4a3a;
}
.navbar-wine-detail .form-inline a:hover{
    color: #6c4a3a;
}
.navbar-wine-detail .form-inline .nav-link.active{
    color: #6c4a3a!important;
}
.form-inline a:not(.active){
    opacity: .6;
}
.form-inline a:active{
    opacity: 1;
}
.btn-check:focus + .btn, .btn:focus {
    box-shadow: none !important;
}
>>>.btn-secondary{
    background-color: unset!important;
    border-color: transparent!important;
} 
#navbar-toggle{
    font-size: 2.25rem;
}

@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);
 .social-container {
   width: 100%;
	 display: flex;
   justify-content: flex-end;
}
 .social-icons {
	 padding: 0;
	 list-style: none;
	 margin: 1em;
}
 .social-icons li {
	 display: inline-block;
	 position: relative;
	 font-size: 1em;
}
 .social-icons i {
	 color: white;
	 position: absolute;
	 top: 21px;
	 left: 21px;
	 transition: all 265ms ease-out;
}
 .social-icons a {
	 display: inline-block;
}
 .social-icons a:before {
	 content: " ";
	 width: 60px;
	 height: 60px;
	 display: block;
}
 .social-icons a:hover:before {
	 transform: scale(1);
	 -ms-transform: scale(1);
	 -webkit-transform: scale(1);
	 content: " ";
	 width: 60px;
	 height: 60px;
	 border-radius: 100%;
	 display: block;
	 background: linear-gradient(45deg, #a48242, #a48242);
	 transition: all 265ms ease-out;
}
 .social-icons a:hover i {
	 transform: scale(2.2);
	 -ms-transform: scale(2.2);
	 -webkit-transform: scale(2.2);
	 background: -webkit-linear-gradient(45deg, white, white);
	 -webkit-background-clip: text;
	 -webkit-text-fill-color: transparent;
	 transition: all 265ms ease-in;
}

</style>
