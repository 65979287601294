<template> 
    <div class="d-flex align-items-center hero-container">
        <div class="d-flex align-items-center p-5">
            <div class="d-flex flex-column">
                <div class="d-flex">
                    <hr class="d-none d-lg-block align-self-start"/>
                    <h1>{{title}}</h1>
                </div>
                <h2 v-html="subtitle"></h2>
                <button v-if="scrollTo" type="button" class="btn btn-outline-primary my-3" @click="scrollClicked">{{cta}}</button>
                <a v-else href="mailto:info@whatwhenwhere.pt" type="button" class="btn btn-outline-primary my-3">{{cta}}</a>
            </div>
            <img class="d-none d-lg-block p-5" loading=lazy :src="require('../assets/img/' + src)" />
        </div>
    </div>
</template>

<script>
export default {
    props:
    {
        title: {
            type: String
        },
        subtitle: {
            type: String
        },
        src: {
            type: String
        },
        cta: {
            type: String
        },
        scrollTo:
        {
            type: String
        }
    },
    methods: 
    {
        scrollClicked()
        {
            console.log("aqui")
            this.$emit('scroll');
        }
    }
}
</script>

<style scoped>
h1,h2, .button
{
    z-index: 2;
}

h1, h2
{
    color: white;
}
h1
{
    font-size: 36px;
    font-weight: bold;
}

h2
{
    font-size: 18px;
    font-weight: 300;
}
.hero-container
{
    height: 100vh;
    min-height: 800px;
    max-width: 1200px;
}
.hero-container > div
{
    text-align: start;
}

img
{
    object-fit: contain;
    width: 50%;
}

img.img-alternative
{
    position: absolute;
    width: unset;
    padding: 0 !important;
    height: 60%;
    right: 0;
}

hr
{
    border: 1px solid white;
    width: 25px;
    margin-right: 12px;
    margin-left: -35px;
}

.btn-outline-primary
{
    color: #a48242;
    border-color: #a48242;
}

.btn-outline-primary:hover {
    background-color: #a48242;
    border-color: #a48242;
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.25rem #a48242 !important;
}
</style>