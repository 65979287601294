<template>
  <section>
    <!-- Menu -->
    <div class="navbar-container">
      <div class="navbar">
        <ul>
          <li class="py-3"><router-link class="nav-link scrollactive-item" to="/#section-2" active-class="active">{{$t("socialNetworks").toUpperCase()}}</router-link></li>
          <li class="py-3"><router-link class="nav-link scrollactive-item" to="#" active-class="active">{{$t("internalMarketing").toUpperCase()}}</router-link></li>
          <li class="py-3"><router-link class="nav-link scrollactive-item" to="#" active-class="active">{{$t("branding").toUpperCase()}}</router-link></li>
          <li class="py-3"><router-link class="nav-link scrollactive-item" to="#" active-class="active">{{$t("workshops").toUpperCase()}}</router-link></li>
        </ul>
      </div>
      <div class="top-side-menu">
        <b-navbar-brand class="logo-side-menu" href="/">
          <router-link class="nav-link" active-class="active" exact :to="{ name: 'HomePage' }">
            <img loading=lazy src="@/assets/img/logo.png" alt="Logo" title="Logo" />
          </router-link>
        </b-navbar-brand>
        <p class="pop-up-close-p"><img loading=lazy id="btn-close" class="w-100 h-25 sizebtn" src="@/assets/svg/sideMenu/close_black.svg" @click="$emit('close')" alt="close"/></p>
      </div> 
    </div>
  </section>
</template>

<script>
export default {
  data(){
    return{
      
    }
  },
  methods: {
      setLocale(locale) {
          this.$i18n.locale = locale;
          localStorage.setItem('locale', locale);
          this.$router.go(0);
      },
    }

 };
</script>

<style scoped>
.navbar{
    justify-content: center;
    height: 100vh;
}
.logo-side-menu{
  position: absolute;
  top: 3%;
  left: 5%;
  width: auto;
  height: 70px;
}
.logo-side-menu img{
  width: auto;
  height: 70px;
}
.pop-up-close-p
{
  position: absolute;
  top: 3%;
  right: 5%;
  cursor: pointer;
  width: 25px;
}
ul {
  padding: 0;
  margin: 0 auto;  
  list-style-type: none;
}
li a {
  display: block;
  color:black !important;
  text-decoration: none !important;
  font-size: 1.2rem;
}
/* Change the link color on hover */
li a:hover {
  color: rgb(155, 155, 155)!important;
}

@media screen and (max-height: 991px), screen and (max-width: 991px) {
  .navbar-container
  {
    background-color: #e3e1dd;
    width: 100%;
    min-height: 100vh;
  }
}

</style>

